<template>
  <div id="pay-methods">
    <v-card>
      <v-card-title><h2>Payment methods</h2></v-card-title>
      <v-card-title>Payment methods</v-card-title>
    </v-card>
    <v-card class="mt-3">
      <v-card-title>Defult payment method</v-card-title>
      <v-card-actions>
        <v-row>
          <v-col
            cols="12"
            md="12"
            class="pt-0"
          >
            <v-autocomplete
              placeholder="Cash on delivery(COD)"
              outlined
              clearable
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="12"
            class="pt-0"
          >
            <v-btn
              color="primary"
              class="me-3"
              :to="{ name: 'transactions-list', params: { query: this.$route.query } }"
            >
              <span>Save</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="mt-3">
          <v-card-actions>
            <v-icon x-large class="ml-10 mr-12">{{ icons.mdiDatabase }}</v-icon>
            <v-divider vertical></v-divider>
            <v-list-item-avatar class="mx-12">
              <img src="@/assets/images/food/food8.png" max-width="220">
            </v-list-item-avatar>
            <v-list-item-content class="ml-12">
              <v-list-item-title>PayPal</v-list-item-title>
              <v-list-item-subtitle>Customer can buy product and pay directly via PayPal</v-list-item-subtitle>
            </v-list-item-content>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-btn
                  color="primary"
                  class="me-3"
                  :to="{ name: 'transactions-list', params: { query: this.$route.query } }"
                >
                  <span>Save</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="mt-3">
          <v-card-actions>
            <v-icon x-large class="ml-10 mr-12">{{ icons.mdiDatabase }}</v-icon>
            <v-divider vertical></v-divider>
            <v-list-item-avatar class="mx-12">
              <img src="@/assets/images/food/food8.png" max-width="220">
            </v-list-item-avatar>
            <v-list-item-content class="ml-12">
              <v-list-item-title>PayPal</v-list-item-title>
              <v-list-item-subtitle>Customer can buy product and pay directly via PayPal</v-list-item-subtitle>
            </v-list-item-content>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-btn
                  color="primary"
                  class="me-3"
                  :to="{ name: 'transactions-list', params: { query: this.$route.query } }"
                >
                  <span>Save</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-card class="mt-3">
      <v-card-actions>
        <v-icon x-large class="ml-10 mr-12">{{ icons.mdiDatabase }}</v-icon>
        <v-divider vertical></v-divider>
        <v-list-item-avatar class="mx-12">
          <img src="@/assets/images/food/food8.png" max-width="220">
        </v-list-item-avatar>
        <v-list-item-content class="ml-12">
          <v-list-item-title>PayPal</v-list-item-title>
          <v-list-item-subtitle>Customer can buy product and pay directly via PayPal</v-list-item-subtitle>
        </v-list-item-content>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-btn
              color="primary"
              class="me-3"
              :to="{ name: 'transactions-list', params: { query: this.$route.query } }"
            >
              <span>Save</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card> -->
  </div>
</template>

<script>
import { mdiDatabase } from '@mdi/js'

export default {
  data: () => ({
    icons: {
      mdiDatabase,
    },
  }),
}
</script>

<style>

</style>
